import process from "process";
import testConstants from "./constants.test";
import prdConstants from "./constants.production";
import devConstants from "./constants.dev";

const envConstantsMap = {
  production: prdConstants,
  test: testConstants,
  dev: devConstants,
};

// @ts-ignore
const envConstants = envConstantsMap[process.env.DEPLOY_ENV] || devConstants;

export const isPro = process.env.DEPLOY_ENV === "production";

export const {
  SERVER_URL,
  DEPLOY_DIR,
  APP_URL,
  H5_STATIC_SERVER_URL,
  WX_APPID,
  WX_MP_APP_ID,
  SHOW_MAI_DIAN_LOG,
  ALLOW_MOBILE_LOGIN_PAGE,
} = envConstants;

// 倍思教务地址
export const BASE_JW_APP_URL = isPro ? "https://jw.basejy.com" : "http://v5.dev.basejy.com/kuaijw";

// 后端服务前缀
export const BASE_SYSTEM_USER = "/base-system-user";
export const SUPPORT_SERVICE = "/ttfs-support";
export const BASE_SCHOOL_COURSE = "/base-school-course";
export const BASE_SCHOOL_TEACHER = "/base-school-teacher";
export const BASE_SCHOOL = "/base-school";
export const SCHEDULING_ENGINE = "/schedule/engine/scheduleproject";
export const BASE_PARSE = "/base-parse";
export const BASE_SALES = "/base-sales";
export const BASE_USER_BEHAVIOR = "/base-user-behavior";
export const BASE_NCEE_CLASS_SCHEDULING = "/base-nceeclassscheduling"; // 新版课表
export const BASE_ARRANGE_EXAM_ROOM = "/base-arrangexamroom"; // 排监考
export const BASE_SCHEDULING = "/base-newscheduling"; // 新版排课
export const BASE_NEW_SCHEDULING = "/base-newscheduling"; // 新版排课

// 前端localStorage相关Key
export const KUAIXK_TOKEN = "KUAIXK_TOKEN"; // token
export const KUAIXK_USER_INFO = "KUAIXK_USER_INFO"; // 用户信息（停用）
export const KUAIXK_SCHOOL_INFO = "KUAIXK_SCHOOL_INFO"; // 学校信息
export const KUAIXK_VERSION_LOG = "KUAIXK_VERSION_LOG"; // 快排课版本
export const KUAIXK_USER_ID = "KUAIXK_USER_ID"; // 用户id
export const KUAIXK_INVITER_ID = "KUAIXK_INVITER_ID"; // 邀请人id
export const KUAIXK_INVITER_STEPS = "KUAIXK_INVITER_STEPS"; // 被邀请人已经完成的操作记录（游客状态使用）
export const KUAIXK_SELECT_SUBJECT_CURRENT_TASK = "KUAIXK_SELECT_SUBJECT_CURRENT_TASK"; // 提示集合
export const KUAIXK_SCHEDULE_CURRENT_TASK_ID = "KUAIXK_SCHEDULE_CURRENT_TASK_ID"; // 当前方案id
export const KUAIXK_NEW_COURSE_SCHEDULE_CURRENT_TASK_ID =
  "KUAIXK_NEW_COURSE_SCHEDULE_CURRENT_TASK_ID"; // 走班排课当前任务ID
export const KUAIXK_CURRENT_TRAINING_COURSE_SCHEDULE_ID =
  "KUAIXK_CURRENT_TRAINING_COURSE_SCHEDULE_ID"; // 培训班排课当前taskId

export const KUAIJZ_MP_APP_ID = "wxb31a32b804116817"; // 倍思家长微信小程序appid
export const KUAIXK_VISITOR_FROM = "kuaixk_visitor_from"; // 客户来源
export const KUAIXK_VISITOR_REF = "kuaixk_visitor_ref"; // 页面来源
