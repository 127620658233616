// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  return {
    routes: {"jw/school-based-course-selection":{"path":"jw/school-based-course-selection","id":"jw/school-based-course-selection","parentId":"@@/global-layout"},"wecom-auth-login":{"path":"wecom-auth-login","id":"wecom-auth-login","parentId":"@@/global-layout"},"mobile-login":{"path":"mobile-login","id":"mobile-login","parentId":"@@/global-layout"},"welink-login":{"path":"welink-login","id":"welink-login","parentId":"@@/global-layout"},"wx-browser":{"path":"wx-browser","id":"wx-browser","parentId":"@@/global-layout"},"jw/index":{"path":"jw","id":"jw/index","parentId":"@@/global-layout"},"pay-vip":{"path":"pay-vip","id":"pay-vip","parentId":"@@/global-layout"},"index":{"path":"/","id":"index","parentId":"@@/global-layout"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}},
    routeComponents: {
'jw/school-based-course-selection': React.lazy(() => import(/* webpackChunkName: "src__pages__jw__school-based-course-selection" */'../../../src/pages/jw/school-based-course-selection.tsx')),
'wecom-auth-login': React.lazy(() => import(/* webpackChunkName: "src__pages__wecom-auth-login" */'../../../src/pages/wecom-auth-login.tsx')),
'mobile-login': React.lazy(() => import(/* webpackChunkName: "src__pages__mobile-login" */'../../../src/pages/mobile-login.tsx')),
'welink-login': React.lazy(() => import(/* webpackChunkName: "src__pages__welink-login" */'../../../src/pages/welink-login.tsx')),
'wx-browser': React.lazy(() => import(/* webpackChunkName: "src__pages__wx-browser" */'../../../src/pages/wx-browser.tsx')),
'jw/index': React.lazy(() => import(/* webpackChunkName: "src__pages__jw__index" */'../../../src/pages/jw/index.tsx')),
'pay-vip': React.lazy(() => import(/* webpackChunkName: "src__pages__pay-vip" */'../../../src/pages/pay-vip.tsx')),
'index': React.lazy(() => import(/* webpackChunkName: "src__pages__index" */'../../../src/pages/index.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/home/gitlab-runner/builds/F6CB8YZr/0/clientgroup-v5/react/kuaixk/src/layouts/index.tsx')),
},
  };
}
