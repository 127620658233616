import store from "@/store";
import { getVipType } from "@/utils/role/useVipType";
import { Modal, Button, message } from "antd";
import pIcon1 from "./assets/personal/icon-1.png";
import pIcon2 from "./assets/personal/icon-2.png";
import pIcon3 from "./assets/personal/icon-3.png";
import pIcon4 from "./assets/personal/icon-4.png";
import bgPersonal from "./assets/img-personal-bg.png";
import bgSchool from "./assets/img-school-bg.png";
import { APP_URL } from "@/config/constants";
import xzbIcon1 from "./assets/xzb/icon-1.png";
import xzbIcon2 from "./assets/xzb/icon-2.png";
import xzbQrcode from "./assets/xzb/img-qrcode.png";
import xgkIcon1 from "./assets/xgk/icon-1.png";
import xgkIcon2 from "./assets/xgk/icon-2.png";
import xgkIcon3 from "./assets/xgk/icon-3.png";
import xgkIcon4 from "./assets/xgk/icon-4.png";
import xgkIcon5 from "./assets/xgk/icon-5.png";
import rolePermMap from "@/utils/role/rolePermMap";
import imgInsBg from "./assets/imgg-ins-bg.png";
import iconIns1 from "./assets/ins/icon-1.png";
import iconIns2 from "./assets/ins/icon-2.png";
import { IVipRoleType } from "@/services/user";
import iconKuaixk1 from "./assets/kuaixk/icon-1.png";
import iconKuaixk2 from "./assets/kuaixk/icon-2.png";

const commonModalProps = {
  icon: null,
  width: 640,
  footer: false,
  wrapClassName: "modal-content-no-padding",
  closable: true,
  centered: true,
};

interface Config {
  reason?: string;
}

// 开通个人VIP
const showPersonalVipModal = (config?: Config) => {
  const { reason } = config || {};

  const pDescList = [
    {
      icon: pIcon1,
      name1: "一键",
      name2: "秒排",
      primary: "name2",
    },
    {
      icon: pIcon2,
      name1: "广泛",
      name2: "使用",
      primary: "name1",
    },
    {
      icon: pIcon3,
      name1: "专属",
      name2: "客服",
      primary: "name1",
    },
    {
      icon: pIcon4,
      name1: "持续",
      name2: "迭代",
      primary: "name2",
    },
  ];

  Modal.confirm({
    ...commonModalProps,
    content: (
      <div
        style={{
          width: 640,
          padding: 40,
          background: `url(${bgPersonal}) no-repeat`,
          backgroundSize: "100% 100%",
        }}
      >
        <div style={{ height: 130 }}>
          <div
            style={{
              fontSize: 20,
              fontWeight: 500,
              color: "#1D2129",
              width: 360,
              lineHeight: 1.3,
              marginBottom: 12,
            }}
          >
            {reason ? `${reason}，` : ""}开通<span style={{ color: "#258794" }}>个人版VIP</span>
            可使用该功能
          </div>
          <div style={{ fontSize: 15, color: "#4E5969" }}>
            效率飞跃新高度！开启您的高效排课新时代
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around", marginBottom: 40 }}>
          {pDescList.map((item, index) => (
            <div
              key={index}
              style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            >
              <img src={item.icon} style={{ width: 80, height: 80 }} alt="" />
              <span style={{ fontWeight: "bold", fontSize: 16 }}>
                <span style={{ color: item.primary === "name1" ? "#37AEB9" : undefined }}>
                  {item.name1}
                </span>
                <span style={{ color: item.primary === "name2" ? "#37AEB9" : undefined }}>
                  {item.name2}
                </span>
              </span>
            </div>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "center", gap: 8 }}>
          <Button
            style={{ width: 88, borderColor: "transparent", color: "#37AEB9" }}
            onClick={() => window.open(`${APP_URL}#/pay-vip`)}
          >
            查看权益
          </Button>
          <Button
            style={{ width: 88, backgroundColor: "#37AEB9" }}
            type="primary"
            onClick={() => window.open(`${APP_URL}#/pay-vip`)}
          >
            立即开通
          </Button>
        </div>
      </div>
    ),
  });
};

// 开通校园版VIP
const showSchoolVipModal = (config?: Config) => {
  const { reason } = config || {};

  return Modal.confirm({
    ...commonModalProps,
    content: (
      <div
        style={{
          width: 640,
          padding: 40,
          background: `url(${bgSchool}) no-repeat`,
          backgroundSize: "100% 100%",
        }}
      >
        <div style={{ height: 130 }}>
          <div
            style={{
              fontSize: 20,
              fontWeight: 500,
              color: "#1D2129",
              width: 360,
              lineHeight: 1.3,
              marginBottom: 12,
            }}
          >
            {reason ? `${reason}，` : ""}开通<span style={{ color: "#814F13" }}>校园版VIP</span>
            可使用该功能
          </div>
          <div style={{ fontSize: 15, color: "#4E5969" }}>全校协同，开启10秒出课表轻松排课之旅</div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 32 }}>
          <div
            style={{
              padding: "24px 16px",
              borderRadius: 8,
              border: "1px solid #fff",
              background: "#FFF8E9",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 16,
                fontWeight: 500,
                marginBottom: 16,
              }}
            >
              <img src={xzbIcon1} alt="" style={{ width: 24, height: 24, marginRight: 16 }} />
              <span>多排课教师</span>
              <span style={{ color: "#814F13" }}>在线协同</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", fontSize: 16, fontWeight: 500 }}>
              <img src={xzbIcon2} alt="" style={{ width: 24, height: 24, marginRight: 16 }} />
              <span>教务信息全校师生</span>
              <span style={{ color: "#814F13" }}>共享同步</span>
            </div>
          </div>
          <div
            style={{
              padding: 10,
              borderRadius: 8,
              border: "1px solid #fff",
              background: "#FFF8E9",
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <img src={xzbQrcode} style={{ width: 92, height: 92 }} alt="" />
            <div style={{ fontSize: 12, color: "#4E5969", textAlign: "center" }}>
              <div>扫描二维码，联系客服可</div>
              <div>
                申请<span style={{ color: "#814F13" }}>免费体验</span>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center", gap: 8 }}>
          <Button
            style={{ width: 88, backgroundColor: "#814F13" }}
            type="primary"
            onClick={() => window.open(`${APP_URL}#/pay-vip`)}
          >
            查看权益
          </Button>
        </div>
      </div>
    ),
  });
};

// 升级校园版VIP
const showUpgradeSchoolVipModal = () => {
  const descList = [
    {
      icon: xgkIcon1,
      name: "学生选科",
    },
    {
      icon: xgkIcon2,
      name: "智能分班",
    },
    {
      icon: xgkIcon3,
      name: "开班计划",
    },
    {
      icon: xgkIcon4,
      name: "走班排课",
    },
    {
      icon: xgkIcon5,
      name: "更多服务",
    },
  ];

  Modal.info({
    ...commonModalProps,
    content: (
      <div
        style={{
          width: 640,
          padding: 40,
          background: `url(${bgSchool}) no-repeat`,
          backgroundSize: "100% 100%",
        }}
      >
        <div style={{ fontSize: 24, fontWeight: 700, color: "#814F13", marginBottom: 24 }}>
          您当前的会员权益不包含该功能
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 32 }}>
          <div
            style={{
              padding: "16px 34px",
              borderRadius: 8,
              border: "1px solid #fff",
              background: "#FFF8E9",
              width: 270,
            }}
          >
            <div style={{ fontSize: 16, color: "#814F13", fontWeight: 500, marginBottom: 16 }}>
              升级权益，可使用以下功能
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "16px 24px",
                justifyContent: "center",
              }}
            >
              {descList.map((item, i) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 4,
                    fontSize: 12,
                    color: "#4E5969",
                    width: 48,
                  }}
                  key={i}
                >
                  <img src={item.icon} style={{ width: 20, height: 20 }} alt="" />
                  {item.name}
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              padding: 10,
              borderRadius: 8,
              border: "1px solid #fff",
              background: "#FFF8E9",
              display: "flex",
              alignItems: "center",
              gap: 10,
              alignSelf: "flex-end",
            }}
          >
            <img src={xzbQrcode} style={{ width: 92, height: 92 }} alt="" />
            <div style={{ fontSize: 12, color: "#4E5969", textAlign: "center" }}>
              <div>扫描二维码，联系客服可</div>
              <div>
                申请<span style={{ color: "#814F13" }}>免费体验</span>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center", gap: 8 }}>
          <Button
            style={{ width: 88, backgroundColor: "#814F13" }}
            type="primary"
            onClick={() => window.open(`${APP_URL}#/pay-vip`)}
          >
            权益对比
          </Button>
        </div>
      </div>
    ),
  });
};

// 开通机构版VIP
const showInsVipModal = (config?: Config) => {
  const { reason } = config || {};

  return Modal.confirm({
    ...commonModalProps,
    content: (
      <div
        style={{
          width: 640,
          padding: 40,
          background: `url(${imgInsBg}) no-repeat`,
          backgroundSize: "100% 100%",
        }}
      >
        <div style={{ height: 130 }}>
          <div
            style={{
              fontSize: 20,
              fontWeight: 500,
              color: "#1D2129",
              width: 360,
              lineHeight: 1.3,
              marginBottom: 12,
            }}
          >
            {reason ? `${reason}，` : ""}开通<span style={{ color: "#467FF0" }}>机构版VIP</span>
            可使用该功能
          </div>
          <div style={{ fontSize: 15, color: "#4E5969" }}>10秒出课表，解决复杂排课的教务软件</div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 32 }}>
          <div
            style={{
              padding: "24px 16px",
              borderRadius: 8,
              border: "1px solid #fff",
              background: "#EBF1FF",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 16,
                fontWeight: 500,
                marginBottom: 16,
              }}
            >
              <img src={iconIns1} alt="" style={{ width: 24, height: 24, marginRight: 16 }} />
              <span>排课</span>
              <span style={{ color: "#467FF0" }}>条件充足</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", fontSize: 16, fontWeight: 500 }}>
              <img src={iconIns2} alt="" style={{ width: 24, height: 24, marginRight: 16 }} />
              <span>教务信息全校师生</span>
              <span style={{ color: "#467FF0" }}>共享同步</span>
            </div>
          </div>
          <div
            style={{
              padding: 10,
              borderRadius: 8,
              border: "1px solid #fff",
              background: "#EBF1FF",
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <img src={xzbQrcode} style={{ width: 92, height: 92 }} alt="" />
            <div style={{ fontSize: 12, color: "#4E5969", textAlign: "center" }}>
              <div>扫描二维码，联系客服可</div>
              <div>
                申请<span style={{ color: "#467FF0" }}>免费体验</span>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center", gap: 8 }}>
          <Button
            style={{ width: 88, backgroundColor: "#467FF0" }}
            type="primary"
            onClick={() => window.open(`${APP_URL}#/pay-vip`)}
          >
            查看权益
          </Button>
        </div>
      </div>
    ),
  });
};

// 开通快选课VIP
const showKuaixkVipModal = (config?: Config) => {
  const { reason } = config || {};

  return Modal.confirm({
    ...commonModalProps,
    content: (
      <div
        style={{
          width: 640,
          padding: 40,
          background: `url(${bgSchool}) no-repeat`,
          backgroundSize: "100% 100%",
        }}
      >
        <div style={{ height: 130 }}>
          <div
            style={{
              fontSize: 20,
              fontWeight: 500,
              width: 360,
              lineHeight: 1.3,
              marginBottom: 12,
              color: "#B36302"
            }}
          >
            {reason ? `${reason}，` : ""}开通<span style={{ color: "#B36302" }}>快选课VIP</span>
            可使用该功能
          </div>
          <div style={{ fontSize: 15, color: "#B36302", }}>智能选课，多样化课程选择，助力学生个性化成长。</div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 32 }}>
          <div
            style={{
              padding: "24px 16px",
              borderRadius: 8,
              border: "1px solid #fff",
              background: "#FFF8E9",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 16,
                fontWeight: 500,
                marginBottom: 16,
              }}
            >
              <img src={iconKuaixk1} alt="" style={{ width: 24, height: 24, marginRight: 16 }} />
              <span>支持创建</span>
              <span style={{ color: "#B36302" }}>多选课任务</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", fontSize: 16, fontWeight: 500 }}>
              <img src={iconKuaixk2} alt="" style={{ width: 24, height: 24, marginRight: 16 }} />
              <span>课程</span>
              <span style={{ color: "#B36302" }}>多样可自定义</span>
            </div>
          </div>
          <div
            style={{
              padding: 10,
              borderRadius: 8,
              border: "1px solid #fff",
              background: "#FFF8E9",
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <img src={xzbQrcode} style={{ width: 92, height: 92 }} alt="" />
            <div style={{ fontSize: 12, color: "#4E5969", textAlign: "center" }}>
              <div>扫描二维码，联系客服可</div>
              <div>
                申请<span style={{ color: "#B36302", fontWeight: 500 }}>免费体验</span>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center", gap: 8 }}>
          <Button
            style={{ width: 88, backgroundColor: "#B36302" }}
            type="primary"
            onClick={() => window.open(`${APP_URL}#/pay-vip`)}
          >
            查看权益
          </Button>
        </div>
      </div>
    ),
  });
};

export const showOpenVipModalByPerm = (perm: string, reason?: string) => {

  const isKuaixkVipFunc = perm && rolePermMap.KUAIXK.includes(perm);

  if (isKuaixkVipFunc) {
    return showKuaixkVipModal({ reason })
  }

  message.error("访问受限！此功能需要开通VIP");
};

export const showOpenVipModalByRoleTags = (roleTags: IVipRoleType[], reason?: string) => {
  message.error(`${reason || "访问受限"}！此功能需要开通VIP`);
};

export const showOpenVipModalByCurrentUser = (reason?: string) => {
  showSchoolVipModal({ reason });
};

export const showOpenVipModal = ({
  perm,
  roleTags,
  reason,
}: {
  perm?: string;
  roleTags?: IVipRoleType[];
  reason?: string;
}) => {
  if (perm) {
    return showOpenVipModalByPerm(perm, reason);
  }
  if (roleTags) {
    return showOpenVipModalByRoleTags(roleTags, reason);
  }
  showOpenVipModalByCurrentUser(reason);
};
