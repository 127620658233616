import sensors from 'sa-sdk-javascript';
import { getUniqId } from '@/utils/report-utils';
// @ts-ignore
import UaParser from 'ua-parser-js';
import {
  BASE_USER_BEHAVIOR,
  KUAIXK_SCHOOL_INFO,
  SERVER_URL,
  KUAIXK_USER_ID, SHOW_MAI_DIAN_LOG, KUAIXK_VISITOR_FROM,
} from '@/config/constants';
import { SchoolInfo } from '@/services/school';

// 埋点配置参数
const KUAI_SA_UUID = 'kuai_sa_uuid'; // 用户uuid键，标识唯一用户，可用作设备id
export const KUAI_SA_SESSION_INFO = 'kuai_sa_session_info';
const sessionExpiredTime = 30 * 60 * 1000;

// 产品名
const productName = 'kuaixk';

// UA信息
const uaParser = new UaParser(navigator.userAgent);
const uaParserResults = uaParser.getResult();

interface SessionInfo {
  sessionId: string;
  createTime: number; // 创建时间
  lastTime: number; // 最近一次更新时间
}

export const createSession = (sessionId?: string) => {
  const now = Date.now();
  return {
    sessionId: sessionId || getUniqId(),
    createTime: now,
    lastTime: now,
  };
};

const getUrlReportSessionId = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const hashParams = new URLSearchParams(window.location.hash?.split('?')?.[1]);
  return searchParams.get('rSessionId') || hashParams.get('rSessionId');
};

const getUid = () => {
  const id = localStorage.getItem(KUAI_SA_UUID);
  if (id) {
    return id;
  }
  const newId = getUniqId();
  localStorage.setItem(KUAI_SA_UUID, newId);
  return newId;
};

// 优先接收url中sessionId，其次获取本地sessionId，没有则新建sessionId
export const getSessionId = () => {
  const rSessionId = getUrlReportSessionId()
  if (rSessionId) {
    const sessionInfo = createSession(rSessionId);
    localStorage.setItem(KUAI_SA_SESSION_INFO, JSON.stringify(sessionInfo));
    return sessionInfo.sessionId;
  }
  const sessionInfoStr = localStorage.getItem(KUAI_SA_SESSION_INFO);
  // 1.不存在session
  if (!sessionInfoStr) {
    const sessionInfo = createSession();
    localStorage.setItem(KUAI_SA_SESSION_INFO, JSON.stringify(sessionInfo));
    return sessionInfo.sessionId;
  }
  // 2.存在session
  const sessionInfo = JSON.parse(sessionInfoStr) as SessionInfo;
  const lastTime = sessionInfo.lastTime;
  const now = Date.now();

  // 2.1超过30分钟没有发生上报事件，重新生成一个session
  if (now - lastTime > sessionExpiredTime) {
    refreshSession()
    return sessionInfo.sessionId;
  }

  // 2.2没有超过30分钟，更新lastTime
  sessionInfo.lastTime = Date.now();
  localStorage.setItem(KUAI_SA_SESSION_INFO, JSON.stringify(sessionInfo));
  return sessionInfo.sessionId;
};

const getUserId = () => {
  return localStorage.getItem(KUAIXK_USER_ID) || '';
};

const getUserSession = (params?: Record<any, any>) => {
  // UserSession数据结构：⽤户⼀个访问session记录⼀条，
  // id #与UserEvent中的session_id为⼀对多的关系
  // user_id #相关的user_id
  // ip #⽤户IP地址
  // province #所在省份
  // city #所在城市
  // hardware #硬件
  // os #系统
  // product_name #产品名称
  // start_time #session创建时间
  // last_time #最后⼀次事件时间

  const sessionInfo = JSON.parse(localStorage.getItem(KUAI_SA_SESSION_INFO)!) as SessionInfo;
  return {
    ...params,
    id: sessionInfo.sessionId,
    user_id: getUserId(),
    hardware: `${uaParserResults.device.vendor}_${uaParserResults.device.model}`,
    os: `${uaParserResults.os.name}_${uaParserResults.os.version}`,
    product_name: productName,
    start_time: sessionInfo.createTime,
    last_time: sessionInfo.lastTime,
    // uaInfo: uaParserResults,
  };
};

const init = () => {
  sensors.init({
    // show_log: SHOW_MAI_DIAN_LOG,
    show_log: false,
    server_url: `${SERVER_URL}${BASE_USER_BEHAVIOR}/behavior/without/l/event`,
    is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
    use_client_time: true,
    send_type: 'beacon',
    heatmap: {
      //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
      clickmap: 'not_collect',
      //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
      scroll_notice_map: 'not_collect',
    },
    max_string_length: 2000,
  });

  // UserEvent数据结构：
  // id #唯一id
  // user_id #⽤户ID
  // session_id #⽤户⼀次活跃对应⼀个session，关联到UserSession表， 取properties.session_id
  // product_name #产品名称
  // event_key #全⼩写下划线。产品名_模块名_事件名, 取event
  // event_info #JSON，具体事件类型附带的信息，例如⽀付的事件，附带⽀付⾦额、渠道
  // create_time #事件发⽣时间, 取time

  // 设置公共属性，由于一些字段无法设置，设置自定义自动时加前缀符号"_"
  sensors.registerPage({
    _uid: getUid,
    _session_id: getSessionId,
    _user_id: getUserId,
    _product_name: productName,
    _user_session: () => {
      const schoolInfo = JSON.parse(
        localStorage.getItem(KUAIXK_SCHOOL_INFO) || '{}',
      ) as SchoolInfo;

      return {
        ...getUserSession(),
        school_id: schoolInfo.id ?? null,
        school_name: schoolInfo.name ?? null,
      }
    },
  });

};

const getBdVid = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get('bd_vid')
}

const getVisitorFrom = () => {
  const queryParams = new URLSearchParams(window.location.search)
  return queryParams.get('f') || sessionStorage.getItem(KUAIXK_VISITOR_FROM)
}

const event = (name: string, params?: Record<string, any>) => {
  const eventInfo: any = {
    ...params,
    session_id: getSessionId(),
  }

  if (getBdVid()) {
    eventInfo.bd_vid = getBdVid()
  }
  if (getVisitorFrom()) {
    eventInfo.sem_from = getVisitorFrom()
  }

  sensors.track(name, {
    _event_info: eventInfo
  });
};

const pageEvent = (name: string, params?: Record<string, any>) => {
  const preProps = sensors.getPresetProperties();
  return event(name, {
    ...params,
    referrer: preProps.$referrer ?? null,
  });
};

const apiEvent = (name: string, result?: boolean, params?: Record<string, any>) => {
  const res = result ?? true;
  event(name, {
    ...params,
    result: res ? 'ok' : 'error',
  });
};

const refreshSession = () => {
  const sessionInfo = createSession();
  localStorage.setItem(KUAI_SA_SESSION_INFO, JSON.stringify(sessionInfo));
}

export default {
  init,
  event,
  pageEvent,
  apiEvent,
  refreshSession,
};
