import {
  KUAIXK_SCHOOL_INFO, KUAIXK_TOKEN, KUAIXK_USER_ID, KUAIXK_USER_INFO,
} from '@/config/constants';

function getCookieMap () {
  return document.cookie.split(';').reduce((map, item) => {
    const tokens = item.split('=')
    map[tokens[0].trim()] = tokens[1]
    return map
  }, {} as Record<string, string>)
}

function getEffectiveDomain() {
  return window.location.hostname.split('.').slice(-2).join('.')
}

// 设置token到cookie
export function setBaseToken2cookie(memberToken: string) {
  document.cookie = `baseToken=${memberToken};domain=${getEffectiveDomain()};path=/`
}

// 移除cookie中的token
export function removeBaseToken2Cookie() {
  document.cookie = `baseToken="";domain=${getEffectiveDomain()};max-age=-1;path=/`
}

// 从cookie获取token
export function getBaseToken2cookie() {
  return getCookieMap()['baseToken']
}

// 获取token
export function getUserToken () {
  const baseToken = getBaseToken2cookie()
  // 存在通用token，保存到本地存储并返回；否则返回本地token
  if (baseToken) {
    localStorage.setItem(KUAIXK_TOKEN, baseToken)
    return baseToken
  }
  return localStorage.getItem(KUAIXK_TOKEN)
}

export function removeUserTokenWithRelation () {
  localStorage.removeItem(KUAIXK_TOKEN)
  localStorage.removeItem(KUAIXK_USER_ID)
  localStorage.removeItem(KUAIXK_SCHOOL_INFO);
  localStorage.removeItem(KUAIXK_USER_INFO);
  removeBaseToken2Cookie()
}
